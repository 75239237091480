var Pages = Pages || {};
/* ================================
 * Home
 * ================================ */

Pages.home = function(app) {
	'use strict';

	var _ = this;
	var A = app;


	/* ================================
	 * Home
	 * ================================ */

	_.init = function() {
		console.log('home is init');

		$modal.modaal({
			type: 'inline',
			animation_speed: 150,
		});

		$document.on('click', '.js-share', Sharer);

		function googleMapsInit () {

			var geo = {
				long: 48.7514354,
				lat	: 7.4561977,
			};
			// Basic options for a simple Google Map
			// For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
			var mapOptions = {
				// How zoomed in you want the map to start at (always required)
				zoom: 15,
				scrollwheel: false,

				// The latitude and longitude to center the map (always required)
				center: new google.maps.LatLng(geo.long, geo.lat), // New York

				// How you would like to style the map.
				// This is where you would paste any style found on Snazzy Maps.
				styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
			};

			// Get the HTML DOM element that will contain your map
			// We are using a div with id="map" seen below in the <body>
			var mapElement = document.querySelector('.google-maps');

			// Create the Google Map using our element and options defined above
			var map = new google.maps.Map(mapElement, mapOptions);

			var picker = {
				path: 'M18.3,0C8.2,0,0,8.2,0,18.3c0,8.3,5.6,15.5,13.6,17.6l4.6,7.3l4.6-7.3c8.1-2.1,13.6-9.4,13.6-17.7C36.5,8.2,28.4,0,18.3,0z M27,28.8h-3.8V18.6h-9.8v10.2H9.6v-21h3.8v8.9h9.8v0.4V7.8H27V28.8z',
				fillOpacity: 1,
				fillColor: 'black',
			};

			// Let's also add a marker while we're at it
			var marker = new google.maps.Marker({
				position: new google.maps.LatLng(geo.long, geo.lat),
				map: map,
				icon: picker,
				title: 'Heschung'
			});

			$(mapElement).addClass('is-active');
		}

		var match = typeof window.matchMedia != 'undefined' ? window.matchMedia("(min-width: 1025px)").matches : true;

		if ( match ) {
			google.maps.event.addDomListener( window, 'load', googleMapsInit );
		}


		/*
		 * Search user
		 */

		$('#form_signin').submit(function (e) {
			e.preventDefault();

			var $form = $(this),
				url = $form.attr('data-ajax-url'),
				$nClient = $form.find('input[name="client"]'),
				nClientVal = $nClient.val(),
				regex = new RegExp('^[a-zA-Z0-9]+$');

			if ( nClientVal != '' && regex.test(nClientVal) ) {

				$.ajax({
					dataType: 'json',
					url: url,
					type: 'post',
					data: $form.serialize(),
					beforeSend: function() {
						$form.addClass('is-waiting');
					},
					complete: function () {
						$form.removeClass('is-waiting');
					},
					success: function (response) {

						if ( response.status == 'success' ) {
							var $container 	= $('.container-form-update'),
								$form       = $container.find('.form_signup'),
								$nClient    = $form.find('input[name="numero_client"]'),
								$civ        = $form.find('input[name="civ"]'),
								$name       = $form.find('input[name="name"]'),
								$forname    = $form.find('input[name="forname"]'),
								$address1   = $form.find('input[name="address1"]'),
								$address2   = $form.find('input[name="address2"]'),
								$cp         = $form.find('input[name="cp"]'),
								$city       = $form.find('input[name="city"]'),
								$country    = $form.find('input[name="country_code"]'),
								$tel        = $form.find('input[name="tel"]'),
								$email      = $form.find('input[name="email"]'),
								$born       = $form.find('input[name="born"]'),
								$newsletter = $form.find('input[name="newsletter"]'),
								$sms        = $form.find('input[name="sms"]'),
								data        = response.data;

							var divers = data.Divers.split(';');
							var divers_temp = {};

							for (var key in divers) {
								divers[key] = divers[key].split('=');

								divers[key][0] = divers[key][0].replace('Opt IN ', '');
								divers[key][0] = divers[key][0].replace('SMS', 'sms');
								divers[key][0] = divers[key][0].replace('MAIL', 'newsletter');

								divers[key][1] = ('N' === divers[key][1]) ? false : ('Y' === divers[key][1]) ? true: divers[key][1];

								divers_temp[divers[key][0]] = divers[key][1];
							}

							if (Object.keys(divers_temp).length) {
								data.Divers = divers_temp;
							}


							$nClient.attr('value', 	nClientVal);

							$civ.removeAttr('checked');
							if ( typeof data.Civ != 'undefined' ) {
								if ( data.Civ == 'M' ) {
									$form.find('input[name="civ"][value="M."]').attr('checked', true);
								} else {
									$form.find('input[name="civ"][value="'+data.Civ+'"]').attr('checked', true);
								}
							} else {
								$form.find('input[name="civ"][value="M."]').attr('checked', true);
							}

							$name.attr('value',         data.Nom);
							$forname.attr('value',      data.Prenom);
							$address1.attr('value',     data.Adresse1);
							$address2.attr('value',     data.Adresse2);
							$cp.attr('value',           data.CP);
							$city.attr('value',         data.Ville);
							$country.attr('value',      data.CodePays.toLowerCase());
							$tel.attr('value',          data.Tel);
							$email.attr('value',        data.Email);
							$born.attr('value',         data.DateNaissance);
							$newsletter.attr('checked', data.Divers.newsletter);
							$sms.attr('checked',        data.Divers.sms);

							openASide($container);

							$('.main-aside [name="country"]').countrySelect({
								defaultCountry: data.CodePays.toLowerCase(),
							});

							$('.main-aside').find('input:not([type="submit"])').trigger('change');

						} else if ( response.status == 'error' ) {
							$('#modaal-content').html('<div>'+response.error_message+'</div>');
							$modal.trigger('click');
						}
					},
				});
			} else {
				$('#modaal-content').html('<div>' + lang.error_message_input_missing + '</div>');
				$modal.trigger('click');
			}

			return false;
		});


		/*
		 * Form step
		 */

		$('#form_signup_step').submit(function (e) {
			e.preventDefault();

			var $form 		 = $(this),
				$nameStep	 = $form.find('input[name="name"]'),
				$forNameStep = $form.find('input[name="forname"]');

			if ( $nameStep.val() != '' && $forNameStep.val() != '' ) {

				var $container 	= $('.container-form-new'),
					$form 		= $container.find('.form_signup'),
					$name 		= $form.find('input[name="name"]'),
					$forname 	= $form.find('input[name="forname"]');

				$name.attr('value', 	$nameStep.val());
				$forname.attr('value', 	$forNameStep.val());

				openASide($container);

				$('.main-aside [name="country"]').countrySelect(countryParams);

				$('.main-aside').find('input:not([type="submit"])').trigger('change');

			} else {
				$('#modaal-content').html('<div>' + lang.error_message_input_missing + '</div>');
				$modal.trigger('click');
			}

			return false;
		});

		$body.on('submit', '.form_signup', function (e) {
			e.preventDefault();

			var $form = $(this);

			$.ajax({
				dataType: 'json',
				url: $form.attr('action'),
				type: 'post',
				data: $form.serialize(),
				beforeSend: function() {
					$form.addClass('is-waiting');
				},
				complete: function () {
					$form.removeClass('is-waiting');
				},
				success: function (response) {

					if ( response.status == 'success' ) {
						var data = response.data;

						$('#modaal-content').html('<div>'+data.modal+'</div>');

						if ( $form.closest('.main-aside').length ) {
							closeASide();

							setTimeout(function() {
								$modal.trigger('click');
							}, 500);
						} else {
							$modal.trigger('click');
						}

					} else if ( response.status == 'error' ) {
						$('#modaal-content').html('<div>'+response.error_message+'</div>');
						$modal.trigger('click');
					}
				},
			});

			return false;
		});
	};

};
